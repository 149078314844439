<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Ordenes</span> </div>
            <div class="card-body">
                <div class="alert alert-warning" v-if="!ordenes.length">
                    No se han registrado ordenes medicas...
                </div>
                <div class="table-responsive">
                    <div v-for="or in ordenes" :key="or.id">
                        <span class="text-info">Orden CLinica No. {{or.id}}</span>
                        <table class="table table-bordered" >
                            <tr class="table-primary">
                                <th>Codigo</th>
                                <th>Servicio</th>
                                <th>Cantidad</th>
                                <th>Observacion</th>
                            </tr>
                            <tr v-for="item in or.items" :key="item.id">
                                <td>{{ item.servicio.codigo }}</td>
                                <td>{{ item.servicio.descripcion }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>{{ item.observacion }}</td>
                            </tr>  
                                                        
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import ordenClinicaService from "../../../../services/ordenClinicaService";
export default {
    props: ['idHistoria'],
    data(){
        return{
            ordenes: [],
        }
    },
    methods: {
        async cargarOrdenes(){
            const response =await ordenClinicaService.showOrdenByHc(this.idHistoria);
            this.ordenes= response.data;
        }
    },
    created() {
        this.cargarOrdenes();
    }
}
</script>


<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Formulas</span> </div>
            <div class="card-body">
                <div class="alert alert-warning" v-if="!formulas.length">
                    No se han registrado formulas medicas...
                </div>
                <div class="table-responsive" v-for="form in formulas" :key="form.id">
                    <div >
                        <span class="text-info">Formula No. {{form.id}}</span>
                        <table class="table table-bordered" >
                            <tr class="table-primary">
                                <th>Codigo</th>
                                <th>Servicio / Medicamento</th>
                                <th>Posologia</th>
                                <th>Total</th>
                            </tr>
                            <tr v-for="item in form.items" :key="item.id">
                                <td>{{ item.servicio.codigo }}</td>
                                <td>{{ item.servicio.descripcion }}</td>
                                <td>
                                    {{ item.dosis }} cada {{ item.frecuencia }}
                                    {{ item.unidad_frecuencia }} Por {{ item.duracion }} dias 
                                </td>
                                <td>{{ item.total }}</td>
                            </tr>  
                                                        
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import formulaMedicaService from "../../../../services/formulaMedicaService";
export default {
    props: ['idHistoria'],
    data(){
        return{
            formulas: [],
        }
    },
    methods: {
        async cargarFormulas(){
            const response =await formulaMedicaService.showFormulaByHc(this.idHistoria);
            this.formulas= response.data;
        }
    },
    created() {
        this.cargarFormulas();
    }
}
</script>


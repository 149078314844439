<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Diagnostico</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div v-for="diag in impresion_dx" :key="diag.id">
                        <span class="text-info">Diagnostico No. {{diag.id}}</span>
                        <table class="table table-bordered" >
                            <tr class="table-primary">
                                <th>Codigo</th>
                                <th>Descripcion</th>
                            </tr>
                            <tr v-for="item in diag.items" :key="item.id">
                                <td>{{ item.diagnostico.ID_DIAG }}</td>
                                <td>{{ item.diagnostico.DESC_DIAG }}</td>
                            </tr>  
                                                        
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import impresionDxService from "../../../../services/impresionDxService";
export default {
    props: ['idHistoria'],
    data(){
        return{
            impresion_dx: [],
        }
    },
    methods: {
        async cargarDiagnoticos(){
            const response =await impresionDxService.showImpresionDxByHc(this.idHistoria);
            this.impresion_dx= response.data;
        }
    },
    created() {
        this.cargarDiagnoticos();
    }
}
</script>


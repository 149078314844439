<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-user-md"> </i> Examen Fisico</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <h6 title="Presion Arterial Sistolica / Presion Arterial Diastolica"><i class="fa fa-stethoscope fa-fw"></i> PAs / PAd : {{examenFisico.pa_sistolica}} / {{examenFisico.pa_diastolica}} <span class="text-danger" title="milimetros de mercurio">(mm Hg)</span></h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6><i class="fa fa-lungs fa-fw"></i> Respiración : {{examenFisico.respiracion}} <span class="text-danger">(R/min)</span></h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6><i class="fa fa-history fa-fw"></i> Pulso : {{examenFisico.pulso}} <span class="text-danger" title="latidos por minuto">(lat/min)</span></h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6><i class="fa fa-thermometer fa-fw"></i> Temperatura : {{examenFisico.temperatura}} <span class="text-danger">(ºC)</span></h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6><i class="fa fa-weight fa-fw"></i> Peso : {{examenFisico.peso}} <span class="text-danger">(kg)</span> </h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6><i class="fa fa-ruler-vertical fa-fw"></i> Talla : {{examenFisico.talla}} <span class="text-danger">(cm)</span></h6>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import examenFisicoService from "../../../../services/examenFisicoService";
export default {
    props: ['idHistoria'],
    data(){
        return{
            examenFisico:{},
        }
    },
    methods: {
        async cargarExamenFisico(){
            const response =await examenFisicoService.showExamenByHc(this.idHistoria);
            this.examenFisico= response.data;
        }
    },
    created() {
        this.cargarExamenFisico();
    }
}
</script>


<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Exploracion Fisica</span> </div>
            <div class="card-body">
                <div class="form-row">
                <div class="form-group col-md-4" v-for="item in partes_fisicas" :key="item.id">
                        <h6 > {{item.nombre}} : {{item.detalle}} </h6>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import partesFisicasService from "../../../../services/partesFisicasService";
import historiaExploracionFisicaService from "../../../../services/historiaExploracionFisicaService";
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria','finalizada'],
    data(){
        return {
            partes_fisicas:[],
        }
    },
    methods:{
        async cargarPartesFisicas(){
            const response = await partesFisicasService.index();
            this.partes_fisicas= response.data.map(x=>{
                return {
                    ...x,
                    detalle:''
                }
            });
        },
    },
    async created(){
        this.cargarPartesFisicas();
        
            const response = await historiaExploracionFisicaService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                     response.data.forEach(x=>{
                        let i = this.partes_fisicas.findIndex(p => parseInt(p.id) === parseInt(x.id_parte_fisica));
                        if(i!==-1)  this.partes_fisicas[i].detalle = x.detalle;
                    });
                    
                }
        
    }
}
</script>